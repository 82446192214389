document.addEventListener('turbolinks:load', bindPasswordValidators)

export default function bindPasswordValidators() {
  const user_password =
    document.querySelector('input#user_password') ||
    document.querySelector('input#password')
  const password_confirmation =
    document.querySelector('input#user_password_confirmation') ||
    document.querySelector('input#passwordConfirmation')
  if (!user_password || !password_confirmation) return
  const pw_checkmark = document.querySelector('.password-checkmark')
  const pw_conf_checkmark = document.querySelector(
    '.password-confirmation-checkmark'
  )
  const regex = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[/\\=+_~"'|<>()#?!@$%^&*.-]).{8,70}$/
  )

  document.querySelectorAll('.signup-input').forEach((item) => {
    item.addEventListener('change', function () {
      if (
        this.id === 'user_password' ||
        this.id === 'password' ||
        this.id === 'user_password_confirmation' ||
        this.id === 'passwordConfirmation'
      )
        return
      const field = this.closest('.field')
      const error_message = field.querySelector('.error-message')
      if (!error_message) return
      error_message.remove()
      field.querySelectorAll('.field_with_errors').forEach((field) => {
        field.classList.add('cleared')
      })
    })
  })

  // Prevent double binding
  user_password.removeEventListener('change', validatePassword)
  password_confirmation.removeEventListener('change', validateConfirmPassword)

  user_password.addEventListener('change', validatePassword)
  password_confirmation.addEventListener('change', validateConfirmPassword)

  function validatePassword() {
    let field = this
    pw_checkmark.classList.add('hidden')
    pw_conf_checkmark.classList.add('hidden')
    let error_span = field.closest('.field').querySelector('.error-message')
    if (field.value === '') return
    if (regex.test(field.value)) {
      if (error_span) error_span.remove()
      pw_checkmark.classList.remove('hidden')
      field.parentElement.classList.add('cleared')
    } else {
      if (!error_span) {
        error_span = document.createElement('span')
        error_span.classList.add('error-message')
        field.closest('.field').appendChild(error_span)
      }
      error_span.innerText = 'Password requirements not met'
    }
    let confirmation_error_span = password_confirmation
      .closest('.field')
      .querySelector('.error-message')
    if (password_confirmation.value === '') return
    if (password_confirmation.value === field.value) {
      password_confirmation.parentElement.classList.add('cleared')
      if (confirmation_error_span) confirmation_error_span.remove()
      pw_conf_checkmark.classList.remove('hidden')
    } else {
      if (!confirmation_error_span) {
        confirmation_error_span = document.createElement('span')
        confirmation_error_span.classList.add('error-message')
        password_confirmation
          .closest('.field')
          .appendChild(confirmation_error_span)
      }
      confirmation_error_span.innerText = 'Passwords do not match'
    }
  }

  function validateConfirmPassword() {
    let field = this
    let error_span = field.closest('.field').querySelector('.error-message')
    if (error_span) error_span.remove()
    pw_conf_checkmark.classList.add('hidden')
    if (field.value === '') return
    if (user_password.value === field.value) {
      field.parentElement.classList.add('cleared')
      pw_conf_checkmark.classList.remove('hidden')
    } else {
      error_span = document.createElement('span')
      error_span.classList.add('error-message')
      field.closest('.field').appendChild(error_span)
      error_span.innerText = 'Passwords do not match'
    }
  }
}
